function sortObjectByDate(obj) {
  const entries = Object.entries(obj);

  function parseDate(dateStr) {
    const monthMap = {
      Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
      Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
    };

    const [monthStr, year] = dateStr.split(' ');
    const month = monthMap[monthStr];
    return new Date(year, month);
  }

  entries.sort((a, b) => parseDate(a[0]) - parseDate(b[0]));
  return Object.fromEntries(entries);
}

export const flaggedCreatorData = ({ creatorAccountsDeplatformedData }, showFullData) => ({
  labels: Object.keys(sortObjectByDate(creatorAccountsDeplatformedData)).slice(showFullData ? undefined : -5),
  datasets: [
    {
      label: 'Accounts de-platformed',
      data: Object.values(sortObjectByDate(creatorAccountsDeplatformedData)).slice(showFullData ? undefined : -5),
      backgroundColor: '#FF3BA4',
      borderRadius: 4
    }
  ]
})

export const flaggedUserData = ({ userAccountsDeplatformedData }, showFullData) => ({
  labels: Object.keys(sortObjectByDate(userAccountsDeplatformedData)).slice(showFullData ? undefined : -5),
  datasets: [
    {
      label: 'Accounts de-platformed',
      data: Object.values(sortObjectByDate(userAccountsDeplatformedData)).slice(showFullData ? undefined : -5),
      backgroundColor: '#FF3BA4',
      borderRadius: 4,
    }
  ]
})


export const healthyStreamingMinutesOptions = ({ healthyStreamingMinutesOptionsData }, showFullData) => ({
  labels: Object.keys(sortObjectByDate(healthyStreamingMinutesOptionsData)).slice(showFullData ? undefined : -5),
  datasets: [{
    data: Object.values(sortObjectByDate(healthyStreamingMinutesOptionsData)).slice(showFullData ? undefined : -5),
    backgroundColor: '#FF3BA4',
    borderRadius: 4
  }]
});


export const usersVideoObscenityOptions = (
  { usersVideoObscenityData },
  showFullData
) => ({
  labels: Object.keys(sortObjectByDate(usersVideoObscenityData)).slice(showFullData ? undefined : -5),
  datasets: [
    {
      data: Object.values(sortObjectByDate(usersVideoObscenityData)).slice(showFullData ? undefined : -5),
      backgroundColor: '#FF3BA4',
      borderRadius: 4
    }]
});

export const creatorsVideoObscenityOptions = ({ creatorsVideoObscenityData }, showFullData = false) => {
  const sortedData = sortObjectByDate(creatorsVideoObscenityData)

  return {
    labels: Object.keys(sortedData).slice(showFullData ? undefined : -5),
    datasets: [{
      data: Object.values(sortedData).slice(showFullData ? undefined : -5),
      backgroundColor: '#FF3BA4',
      borderRadius: 4,
    }]
  }
};


export const getBarOptions = (title, stacked = false, legend = false) => ({
  plugins: {
    title: {
      display: true,
      text: title,
      align: legend ? 'start' : 'center'
    },
    legend: {
      display: legend,
      position: 'top',
      align: 'end',
      labels: {
        usePointStyle: true,
      }
    },
    datalabels: {
      display: true,
      color: '#000',
      font: {
        weight: 'bold'
      },
      formatter: function (value) {
        return !value ? null : value + ' %';
      }
    }
  },
  scales: {
    y: {
      stacked,
      beginAtZero: true,
      grid: {
        color: '#e5e5e5',
        lineWidth: 0.2,
      },
      ticks: {
        color: 'black',
        font: {
          weight: 'bolder'
        }
      }
    },
    x: {
      // display: false
      stacked,
      grid: {
        display: false
      },
    }
  },
  responsive: true,
  maintainAspectRatio: false
})

export const getDoughnutTitlePlugin = ({ cellsFlagged }) => ({
  id: 'text',
  beforeDraw: function (chart) {
    var width = chart.width,
      height = chart.height,
      ctx = chart.ctx;

    ctx.restore();

    ctx.font = "bold 16px Lato";
    ctx.textBaseline = "middle";

    var text1 = `${cellsFlagged.value}%`,
      textX1 = Math.round((width - ctx.measureText(text1).width) / 2),
      textY1 = height / 2;

    ctx.fillText(text1, textX1, textY1 - 16);
    ctx.font = "bold 12px Lato";
    var text2 = "Clean Calls",
      textX2 = Math.round((width - ctx.measureText(text2).width) / 2),
      textY2 = height / 2;
    var text3 = cellsFlagged.monthName,
      textX3 = Math.round((width - ctx.measureText(text3).width) / 2),
      textY3 = height / 2;

    ctx.fillText(text2, textX2, textY2);
    ctx.fillText(text3, textX3, textY3 + 16);
    ctx.save();
  }
})
export const getDoughnutOptions = () => ({
  plugins: {
    legend: { display: false },
    datalabels: {
      display: false,
    },
    tooltips: {
      callbacks: {
        title: function (t, d) {
          return d.labels[t[0].index];
        }
      }
    },
  },
  responsive: true,
  borderColor: "transparent",
  maintainAspectRatio: false
})

export const flaggedCellsData = ({ cellsFlagged }) => ({
  labels: [`% flagged calls`, `% clean calls`],
  datasets: [
    {
      data: [(100 - cellsFlagged.value).toFixed(2), cellsFlagged.value],
      backgroundColor: [
        '#FF3BA4',
        '#FF3BA444',
      ],
      hoverOffset: 4
    }]
})

export const DoughnutLabelPlugin = {
  afterDraw: (chart) => {
    const ctx = chart.chart.ctx;
    ctx.save();
    ctx.font = "10px 'Averta Std CY'";
    const chartCenterPoint = {
      x:
        (chart.chartArea.right - chart.chartArea.left) / 2 +
        chart.chartArea.left,
      y:
        (chart.chartArea.bottom - chart.chartArea.top) / 2 +
        chart.chartArea.top
    };
    chart.config.data.labels.forEach((label, i) => {
      const meta = chart.getDatasetMeta(0);
      const arc = meta.data[i];
      const dataset = chart.config.data.datasets[0];

      // Prepare data to draw
      // important point 1
      const centerPoint = arc.getCenterPoint();
      const model = arc._model;
      let color = model.borderColor;
      let labelColor = model.borderColor;
      if (dataset.polyline && dataset.polyline.color) {
        color = dataset.polyline.color;
      }

      if (dataset.polyline && dataset.polyline.labelColor) {
        labelColor = dataset.polyline.labelColor;
      }

      const angle = Math.atan2(
        centerPoint.y - chartCenterPoint.y,
        centerPoint.x - chartCenterPoint.x
      );
      // important point 2
      const point2X =
        chartCenterPoint.x + Math.cos(angle) * (model.outerRadius + 20);
      let point2Y =
        chartCenterPoint.y + Math.sin(angle) * (model.outerRadius + 20);

      let value = dataset.data[i];
      if (dataset.polyline && dataset.polyline.formatter) {
        value = dataset.polyline.formatter(value);
      }
      let edgePointX = point2X < chartCenterPoint.x ? 10 : chart.width - 10;

      //DRAW CODE
      // first line: connect between arc's center point and outside point
      ctx.strokeStyle = color;
      ctx.beginPath();
      ctx.moveTo(centerPoint.x, centerPoint.y);
      ctx.lineTo(point2X, point2Y);
      ctx.stroke();
      // second line: connect between outside point and chart's edge
      ctx.beginPath();
      ctx.moveTo(point2X, point2Y);
      ctx.lineTo(edgePointX, point2Y);
      ctx.stroke();
      //fill custom label
      const labelAlignStyle =
        edgePointX < chartCenterPoint.x ? "left" : "right";
      const labelX = edgePointX;
      const labelY = point2Y;
      ctx.textAlign = labelAlignStyle;
      ctx.textBaseline = "bottom";

      ctx.fillStyle = labelColor;
      ctx.fillText(value, labelX, labelY);
    });
    ctx.restore();
  }
}

export const pieChartOptions = (title) => ({
  layout: {
    padding: {
      top: 50,
      bottom: 50
    }
  },
  plugins: {
    legend: { display: false },
    tooltip: {
      filter: function (tooltipItem) {
        return tooltipItem.datasetIndex === 0;
      },
      callbacks: {
        label: function (item) {
          return item.label + ": " + item.formattedValue + "%"
        }
      }
    },
    labels: {
      render: 'value',
    },
    title: {
      display: !!title,
      text: title,
      position: 'bottom'
    },
    datalabels: {
      display: true,
      color: '#000',
      font: {
        size: 10,
        weight: 'bold'
      },
      align: 'end',
      textAlign: 'center',
      anchor: 'end',
      formatter: function (value, context) {
        return context.chart.data.labels[context.dataIndex];
      }
    }
  },
  tooltipTemplate: "<%= label %> - <%= value %>",
  responsive: true,
  maintainAspectRatio: false
});

export const creatorsPieChartData = ({ CREATOR_ACCOUNT_DEPLATFORMED }) => ({
  labels: [`Accounts Deplatformed`, `Accounts incentive removal`],
  datasets: [
    {
      data: [Object.values(CREATOR_ACCOUNT_DEPLATFORMED)[0], 100 - Object.values(CREATOR_ACCOUNT_DEPLATFORMED)[0]],
      backgroundColor: [
        '#FF3BA4',
        '#FF3BA444',
      ]
    }
  ]
})
export const usersPieChartData = ({ USER_ACCOUNT_DEPLATFORMED }) => ({
  labels: [`Accounts Deplatformed`, `Accounts incentive removal`],
  datasets: [
    {
      data: [Object.values(USER_ACCOUNT_DEPLATFORMED)[0], 100 - Object.values(USER_ACCOUNT_DEPLATFORMED)[0]],
      backgroundColor: [
        '#FF3BA4',
        '#FF3BA444',
      ]
    }
  ]
})
export const tatPieChartData = ({ tatData }) => ({
  labels: Object.entries(tatData).map(([key]) => `${key}`),
  datasets: [
    {
      data: Object.values(tatData),
      backgroundColor: [
        '#FF3BA4',
        '#FF3BA4BB',
        '#FF3BA488',
        '#FF3BA444',
      ]
    }
  ]
})